import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { getIsAdmin, getIsIndividualCompanyAccess } from "src/utils/helper";
import {
  TeacherSchool,
  TeacherClass,
  TeacherList
} from "src/api/analytics/types/analyticsTypes";
import {
  sendGetSchoolListRequestRequest,
  sendGetTeacherListRequest,
  // The two new separate actions for teacher vs. admin:
  sendGetTeacherClassListRequest,
  sendGetAdminClassListRequest,
  setCurTeacherSchool,
  setCurTeacherClass,
  setCurTeacher,
  resetTeacherAndClassData
} from "src/reducers/auth/analyticsSlice";
import { sendGetAccountInfoRequest } from "src/reducers/auth/accountSlice";

import { AnalyticModal } from "../AnalyticModal";
import { SeityButton } from "../SeityButton";
import { SeityLoader } from "../../SeityLoader";
import SelectBox from "./SelectBox";
import FilterButton from "./FilterButton";

import "./styles.scss";

export interface SeityEditLocationProps {
  isOpen: boolean;
  isLanding: boolean;
  reportType: "Student" | "Staff";
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

// Helper functions to figure out which item is selected
const getSiteIndex = (curTS: TeacherSchool | null, allSites: TeacherSchool[]): number => {
  if (curTS) {
    const idx = allSites.findIndex((site) => site.companyID === curTS.companyID);
    if (idx !== -1) return idx;
  }
  // If no current teacher school,
  // - Admin => -1 means 'All Schools'
  // - Non-admin => pick 0 as default
  return getIsAdmin() ? -1 : 0;
};

const getClassIndex = (curTC: TeacherClass | null, allClasses: TeacherClass[]): number => {
  if (curTC) {
    const idx = allClasses.findIndex((cls) => cls.class01ID === curTC.class01ID);
    if (idx !== -1) return idx;
  }
  return -1;
};

const getTeacherIndex = (curT: TeacherList | null, allTeachers: TeacherList[]): number => {
  if (curT) {
    const idx = allTeachers.findIndex((t) => t.accountID === curT.accountID);
    if (idx !== -1) return idx;
  }
  return -1;
};

export const SeityEditLocation: React.FC<SeityEditLocationProps> = ({
                                                               isOpen,
                                                               isLanding,
                                                               reportType = "Student",
                                                               onClose,
                                                               onCancel
                                                             }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = getIsAdmin();
  const isIndividualCompanyAccess = getIsIndividualCompanyAccess();

  // Pull from Redux
  const {
    isLoading,
    teacherSchoolList,
    teacherList,
    teacherClassList,
    curTeacherSchool,
    curTeacherClass,
    curTeacher
  } = useSelector((state: RootState) => state.analytics);
  const { accountInfo, proSettings } = useSelector((state: RootState) => state.account);

  // Figure out which item is selected by index
  const [siteId, setSiteId] = useState(getSiteIndex(curTeacherSchool, teacherSchoolList));
  const [teacherId, setTeacherId] = useState(getTeacherIndex(curTeacher, teacherList));
  const [classId, setClassId] = useState(getClassIndex(curTeacherClass, teacherClassList));
  // Admin sees no selection by default, non-admin sees 'Site' selected
  const [showSelection, setShowSelection] = useState<"Teacher" | "Class" | "Site" | null>(
      isAdmin ? null : "Site"
  );

  // On mount, fetch needed info
  useEffect(() => {
    if (!proSettings) {
      history.push("/");
      return;
    }
    if (accountInfo.firstName === "") {
      dispatch(sendGetAccountInfoRequest());
    }
    // Always fetch the school list on mount
    dispatch(sendGetSchoolListRequestRequest(isAdmin));
  }, [dispatch, history, proSettings, accountInfo.firstName, isAdmin]);

  // If teacherSchoolList changes, possibly auto-select if siteId = -1 but we do have companyID=0
  useEffect(() => {
    if (teacherSchoolList.length && siteId === -1) {
      const zeroIndex = teacherSchoolList.findIndex((s) => s.companyID === 0);
      if (zeroIndex !== -1) {
        setSiteId(zeroIndex);
      }
    }
  }, [teacherSchoolList, siteId]);

  /**
   * Change Site
   */
  const onChangeSite = (idx: number) => {
    setSiteId(idx);
    setTeacherId(-1);
    setClassId(-1);
    dispatch(resetTeacherAndClassData());

    if (idx === -1) {
      // "All Schools"
      return;
    }

    const selectedSchool = teacherSchoolList[idx];
    if (!selectedSchool) return;

    // If admin or individual-company-access => fetch teacher list
    if (isAdmin || isIndividualCompanyAccess) {
      dispatch(sendGetTeacherListRequest(selectedSchool.companyID));
    } else {
      // Non-admin => fetch teacher class list (via the separate thunk)
      dispatch(sendGetTeacherClassListRequest(selectedSchool.companyID));
    }
  };

  /**
   * Change Teacher
   */
  const onChangeTeacher = (idx: number) => {
    setTeacherId(idx);
    setClassId(-1);
    dispatch(resetTeacherAndClassData());

    if (idx === -1) {
      return;
    }

    const selectedSchool = teacherSchoolList[siteId];
    const selectedTeacher = teacherList[idx];
    if (!selectedSchool || !selectedTeacher) return;

    if (isAdmin || isIndividualCompanyAccess) {
      dispatch(sendGetAdminClassListRequest(selectedSchool.companyID, selectedTeacher.accountID));
    } else {
      dispatch(sendGetTeacherClassListRequest(selectedSchool.companyID));
    }
  };

  /**
   * Change Class
   */
  const onChangeClass = (idx: number) => {
    setClassId(idx);
  };

  /**
   * Reset everything
   */
  const resetSelection = () => {
    if (isAdmin) {
      if (teacherSchoolList.length) {
        const zeroIndex = teacherSchoolList.findIndex((s) => s.companyID === 0);
        if (zeroIndex !== -1) {
          setSiteId(zeroIndex);
        } else {
          setSiteId(-1);
        }
      } else {
        setSiteId(-1);
      }
    } else {
      setSiteId(0);
    }

    setTeacherId(-1);
    setClassId(-1);
    dispatch(resetTeacherAndClassData());
  };

  /**
   * Render main content
   */
  const renderContent = () => {
    const siteLabel =
        siteId !== -1 && teacherSchoolList[siteId]
            ? teacherSchoolList[siteId].schoolName
            : "All Schools";

    let teacherLabel = "All Teachers";
    if (teacherId !== -1 && teacherList[teacherId]) {
      const t = teacherList[teacherId];
      teacherLabel = `${t.firstName} ${t.lastName}`;
    }

    let classLabel = "All Classes";
    if (classId !== -1 && teacherClassList[classId]) {
      classLabel = teacherClassList[classId].classDescription ?? "N/A";
    }

    return (
        <div className="editlocation-container">
          <h2 className="title">Select population</h2>
          <div className="content">
            <div style={{ display: "flex", justifyContent: "center" }}>
            <span className="button-category active">
              {reportType === "Student" ? "Students" : "Staff"}
            </span>
            </div>
            <div className="border-bottom" />

            <div className="item-wrap">
            <span className="left" style={{ width: "100%" }}>
              {proSettings?.companyName}
            </span>
            </div>

            <div className="item-wrap">
              <span className="left">Site</span>
              <FilterButton
                  title={siteLabel || ""}
                  onClick={() => setShowSelection("Site")}
              />
            </div>

            {reportType === "Student" && (
                <div className="item-wrap">
                  <span className="left">Teacher</span>
                  {isAdmin || isIndividualCompanyAccess ? (
                      <FilterButton
                          title={teacherLabel}
                          onClick={() => setShowSelection("Teacher")}
                      />
                  ) : (
                      <span className="right text">
                  {accountInfo.firstName} {accountInfo.lastName}
                </span>
                  )}
                </div>
            )}

            {reportType === "Student" && (
                <div className="item-wrap">
                  <span className="left">Class</span>
                  <FilterButton
                      title={classLabel}
                      onClick={() => setShowSelection("Class")}
                  />
                </div>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            <SeityButton
                label="Reset"
                type="cancel"
                style={{ height: 48, width: 108, marginRight: 10 }}
                onClick={() => resetSelection()}
            />
            <SeityButton
                label="Cancel"
                type="cancel"
                style={{ height: 48, width: 108, marginRight: 10 }}
                onClick={(e) => {
                  if (onCancel) {
                    onCancel(e);
                  }
                }}
            />
            <SeityButton
                label="Apply"
                type="next"
                style={{ height: 48, width: 108 }}
                onClick={(e) => {
                  dispatch(setCurTeacherSchool(siteId > -1 ? teacherSchoolList[siteId] : null));
                  dispatch(setCurTeacherClass(classId > -1 ? teacherClassList[classId] : null));
                  dispatch(setCurTeacher(teacherId > -1 ? teacherList[teacherId] : null));

                  if (onClose) {
                    onClose(e);
                  }
                }}
            />
          </div>

          {isLoading && <SeityLoader showBackgroundMask />}
        </div>
    );
  };

  const customModalStyles = {
    content: {
      height: "550px",
      maxHeight: "550px"
    }
  };

  return (
      <>
        {isLanding ? (
            renderContent()
        ) : (
            <AnalyticModal isOpen={isOpen} header={false} customStyle={customModalStyles}>
              {renderContent()}
            </AnalyticModal>
        )}

        {/* The "select box" if user is currently picking site/teacher/class */}
        {showSelection && (
            <SelectBox
                type={showSelection}
                selIdx={
                  showSelection === "Teacher"
                      ? teacherId
                      : showSelection === "Site"
                          ? siteId
                          : classId
                }
                options={
                  showSelection === "Teacher"
                      ? teacherList
                      : showSelection === "Site"
                          ? teacherSchoolList
                          : teacherClassList
                }
                onClose={() => setShowSelection(null)}
                onChange={(selIdx) => {
                  if (showSelection === "Teacher") {
                    onChangeTeacher(selIdx);
                  } else if (showSelection === "Site") {
                    onChangeSite(selIdx);
                  } else if (showSelection === "Class") {
                    onChangeClass(selIdx);
                  }
                }}
            />
        )}
      </>
  );
};

export default SeityEditLocation;

//all commented out code in this file was commented out to avoid compile errors and will need to be put back in once LS or Cookie is
//implemented

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getTeacherClassList,
  getTeacherSchoolList,
  getTeacherList,
  getClassList,
  getAnalyticsView
} from "../../api/analytics/seityHealthAPI-analytics";
import {
  TeacherSchool,
  TeacherClass,
  TeacherList,
  SeityTeacherSchoolListPayloadResponse,
  SeityAdminSchoolListPayloadResponse,
  SeityAdminClassListPayloadResponse,
  SeityTeacherClassListPayloadResponse
} from "../../api/analytics/types/analyticsTypes";

import {validateToken} from "../utils";

export interface AnalyticsState {
  error: string | null;
  isLoading: boolean;
  teacherSchoolList: TeacherSchool[];
  teacherClassList: TeacherClass[];
  teacherList: TeacherList[];
  curTeacherSchool: TeacherSchool | null;
  curTeacherClass: TeacherClass | null;
  curTeacher: TeacherList | null;
  analyticsView: number;
}

const setInitialState = {
  error: null,
  isLoading: false,
  curTeacherSchool: null,
  curTeacherClass: null,
  curTeacher: null,
  teacherSchoolList: [],
  teacherClassList: [],
  teacherList: [],
  analyticsView: -1
} as AnalyticsState;

const analyticsSlice = createSlice({
  name: "auth",
  initialState: setInitialState,
  reducers: {
    setTeacherSchoolList(state, action) {
      state.teacherSchoolList = action.payload;
      state.isLoading = false;

    },
    setTeacherClassList(state, action) {
      state.teacherClassList = action.payload;
      state.isLoading = false;
    },
    setTeacherList(state, action) {
      state.teacherList = action.payload;
      state.isLoading = false;
    },
    setCurTeacherSchool(state, action) {
      state.curTeacherSchool = action.payload;
    },
    setCurTeacherClass(state, action) {
      state.curTeacherClass = action.payload;
    },
    setCurTeacher(state, action) {
      state.curTeacher = action.payload;
    },
    setAnalyticsView(state, action) {
      state.analyticsView = action.payload;
    },
    resetTeacherAndClassData(state) {
      state.teacherClassList = [];
    },
    resetEditLocation(state) {
      state.curTeacherSchool = null;
      state.curTeacherClass = null;
      state.curTeacher = null;
    },
    resetAnalyticsSlice: () => setInitialState,
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = false;
      }
    }
  }
});
// Be sure to add new slices here to
// be able to access them directly
export const {
  setTeacherSchoolList,
  setTeacherClassList,
  setTeacherList,
  setCurTeacherSchool,
  setCurTeacherClass,
  setCurTeacher,
  setAnalyticsView,
  resetTeacherAndClassData,
  resetEditLocation,
  resetAnalyticsSlice,
  setIsLoading,
  clearApiError,
  apiError
} = analyticsSlice.actions;
export default analyticsSlice.reducer;

// THUNK FUNCTIONS
export const sendGetSchoolListRequestRequest = (isAdmin: boolean) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    dispatch(clearApiError());
    const token = await validateToken(dispatch);

    const response = await getTeacherSchoolList(token, isAdmin);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success && response.data) {
      const data = isAdmin
        ? (response.data as SeityAdminSchoolListPayloadResponse).schoolList
        : (response.data as SeityTeacherSchoolListPayloadResponse).teacherSchoolList;
      dispatch(setTeacherSchoolList(data));
    } else {
      dispatch([]);
      dispatch(apiError({ error: "Error getTeacherSchoolList" })); // put in strings.ts
      console.log("Error getTeacherSchoolList. \nMessage: " + response.message);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

export const sendGetTeacherListRequest = (companyID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    dispatch(clearApiError());

    const token = await validateToken(dispatch);

    dispatch(resetTeacherAndClassData());

    const response = await getTeacherList(token, companyID);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setTeacherList(response.data?.teacherList));
      console.log("Completed getTeacherList");
    } else {
      dispatch(apiError({ error: "Error getTeacherList" }));
      console.log("Error getTeacherList. \nMessage: " + response.message);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

// analyticsSlice.ts (same file where other thunks are)

// THUNK: Non-admin (Teacher) version
export const sendGetTeacherClassListRequest = (companyID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    dispatch(clearApiError());

    const token = await validateToken(dispatch);
    if (!token) {
      // handle invalid token
      dispatch(setIsLoading({ isLoading: false }));
      return;
    }

    // Call the teacherClassList endpoint
    const response = await getTeacherClassList(token, companyID);

    dispatch(setIsLoading({ isLoading: false }));

    if (response.success && response.data) {
      // Cast to SeityTeacherClassListPayloadResponse
      const data = (response.data as SeityTeacherClassListPayloadResponse).teacherClassList;
      dispatch(setTeacherClassList(data));
      console.log("Completed getTeacherClassList (non-admin).");
    } else {
      dispatch(setTeacherClassList([]));
      dispatch(apiError({ error: "Error getTeacherClassList" }));
      console.log("Error getTeacherClassList. \nMessage: " + response.message);
    }
  } catch (err: any) {
    dispatch(setTeacherClassList([]));
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

// THUNK: Admin version
export const sendGetAdminClassListRequest = (companyID: number, accountID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    dispatch(clearApiError());

    const token = await validateToken(dispatch);
    if (!token) {
      // handle invalid token
      dispatch(setIsLoading({ isLoading: false }));
      return;
    }

    // Call the admin class list endpoint
    const response = await getClassList(token, companyID, accountID);

    dispatch(setIsLoading({ isLoading: false }));

    if (response.success && response.data) {
      // Cast to SeityAdminClassListPayloadResponse
      const data = (response.data as SeityAdminClassListPayloadResponse).classList;
      dispatch(setTeacherClassList(data));
      console.log("Completed getClassList (admin).");
    } else {
      dispatch(setTeacherClassList([]));
      dispatch(apiError({ error: "Error getClassList (admin)" }));
      console.log("Error getClassList. \nMessage: " + response.message);
    }
  } catch (err: any) {
    dispatch(setTeacherClassList([]));
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

export const sendGetAnalyticsViewRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    dispatch(clearApiError());

    const token = await validateToken(dispatch);

    const response = await getAnalyticsView(token);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success && response.data) {
      dispatch(setAnalyticsView(response.data));
    } else {
      dispatch(apiError({ error: "Error getAnalyticsView" })); // put in strings.ts
      console.log("Error getAnalyticsView. \nMessage: " + response.message);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};
